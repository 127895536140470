<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 配置证书模板 </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        :locale="{ emptyText: '暂无数据' }"
        @change="onPage"
        :pagination="{
          total: total,
          current: current,
          defaultPageSize: size,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a @click="goEdit(item.id,item.name)">编辑</a>
          </div>
        </template>
      </a-table>
    </div>
   
  </div>
</template>

<script>
const columns = [
  {
    title: "证书名称",
    width: "350px",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "保存时间",
    align: "center",
    dataIndex: "modifyTime",
  },
  {
    title: "操作",
    align: "center",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      current: 1, //页码
      size: 10, // 页数
    };
  },
  // 事件处理器
  methods: {
   
    // 分页
    onPage(e) {
      this.current = e.current;
      this.size = e.pageSize;
      this.getData()
    },
    // 跳编辑页
    goEdit(id,name) {
      this.$router.push({
        path: "/admin/Search/TemplateDetails",
        query: { id: id, name: name },
      });
    },
    // 获取数据
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/export-temp-config/list",
        method: "get",
        params: {
          current: this.current,
          size: this.size,
        },
      }).then((res) => {
        this.loading = false;
        if(res.code == 200 && res.success){
          this.tableData = res.data.records;
          this.total = res.data.total;
        }else{
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 获取数据
    this.getData();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

.modal_box {
  display: flex;
  flex-direction: column;
  // align-items: center;
  .tag {
    flex-shrink: 0;
  }
  .box_upload {
    margin-top: 10px;
  }
}

.table {
  margin-top: 24px;
  /deep/.ant-table-thead > tr > th {
    background: #f6f6fc;
  }
  /deep/.ant-table-footer {
    background: #f6f6fc;
    padding: 12px 16px;
  }
  /deep/.ant-table-thead > tr > th,
  /deep/.ant-table-tbody > tr > td {
    padding: 12px 16px;
  }
  // /deep/.ant-table {
  //   border: 1px solid #eeeeee;
  //   box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
  // }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .operation {
    color: #3681f0;
  }
  .allSellect {
    padding-left: 6px;
  }
}
</style>
