import { render, staticRenderFns } from "./Templatepage.vue?vue&type=template&id=0613f0c3&scoped=true&"
import script from "./Templatepage.vue?vue&type=script&lang=js&"
export * from "./Templatepage.vue?vue&type=script&lang=js&"
import style0 from "./Templatepage.vue?vue&type=style&index=0&id=0613f0c3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0613f0c3",
  null
  
)

export default component.exports