var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 配置证书模板 ")]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.id; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"locale":{ emptyText: '暂无数据' },"pagination":{
        total: _vm.total,
        current: _vm.current,
        defaultPageSize: _vm.size,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"operation",fn:function(item){return [_c('div',{staticClass:"btn_router_link"},[_c('a',{on:{"click":function($event){return _vm.goEdit(item.id,item.name)}}},[_vm._v("编辑")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }